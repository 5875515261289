import React from "react";
import Chatbox from "./chatbox/Chatbox";
import "./App.css";
// import dotenv from 'dotenv'
// require("dotenv").config();

// console.log(process.env.OPENAI_API_KEY);

function App() {
  return (
    <>
      <Chatbox />
    </>
  );
}

export default App;
